var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-menu',{attrs:{"offset-y":"","left":"","nudge-bottom":"14","min-width":"230","content-class":"user-profile-menu-content"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-badge',{staticClass:"ms-4",attrs:{"bottom":"","color":"success","overlap":"","offset-x":"12","offset-y":"12","dot":""}},[_c('v-avatar',_vm._g(_vm._b({staticClass:"v-avatar-light-bg primary--text",attrs:{"size":"40px","color":"primary"}},'v-avatar',attrs,false),on),[_c('v-img',{attrs:{"src":require('@/assets/images/avatars/1.png')}})],1)],1)]}}])},[_c('v-list',[_c('div',{staticClass:"pb-3 pt-2"},[_c('v-badge',{staticClass:"ms-4",attrs:{"bottom":"","color":"success","overlap":"","offset-x":"12","offset-y":"12","dot":""}},[_c('v-avatar',{staticClass:"v-avatar-light-bg primary--text",attrs:{"size":"40px","color":"primary"}},[_c('v-img',{attrs:{"src":require('@/assets/images/avatars/1.png')}})],1)],1),_c('div',{staticClass:"d-inline-flex flex-column justify-center ms-3",staticStyle:{"vertical-align":"middle"}},[(_vm.userData)?_c('span',{staticClass:"text--primary font-weight-semibold mb-n1"},[_vm._v(" "+_vm._s(_vm.userData.name)+" ")]):_vm._e(),_c('small',{staticClass:"text--disabled text-capitalize"},[_vm._v("Admin")])])],1),_c('v-divider',{staticClass:"my-2"}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('router-link',{staticStyle:{"text-decoration":"none","color":"inherit"},attrs:{"to":"/chooseOrg"}},[_c('v-list-item',{style:({ 'background-color': hover ? '#3e3a57' : '' })},[_c('v-list-item-icon',{staticClass:"me-2"},[_c('v-icon',{attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiSwapHorizontal)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Switch Company")])],1)],1)],1)]}}])}),_c('v-divider',{staticClass:"my-2"}),(_vm.userData && _vm.userData.email && _vm.userData.email === 'admin@jdcservices.ca')?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',{staticStyle:{"cursor":"pointer","text-decoration":"none","color":"inherit"},style:({ 'background-color': hover ? '#3e3a57' : '' }),on:{"click":function($event){_vm.userSettingsDialog = true}}},[_c('v-list-item-icon',{staticClass:"me-2"},[_c('v-icon',{attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCog)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Settings")])],1)],1)]}}],null,false,3314978575)}):_vm._e(),(_vm.userData && _vm.userData.email && _vm.userData.email === 'admin@jdcservices.ca')?_c('v-divider',{staticClass:"my-2"}):_vm._e(),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',{staticStyle:{"cursor":"pointer"},style:({ 'background-color': hover ? '#3e3a57' : '' })},[_c('v-list-item-icon',{staticClass:"me-2"},[_c('v-icon',{attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiLogoutVariant)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{on:{"click":function($event){return _vm.signOut()}}},[_vm._v("Log out")])],1)],1)]}}])})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.userSettingsDialog),callback:function ($$v) {_vm.userSettingsDialog=$$v},expression:"userSettingsDialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.userSettingsDialog = false}}},[_c('v-icon',{attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1),_c('v-toolbar-title',[_vm._v("Settings")])],1),_c('v-list',{attrs:{"three-line":"","subheader":""}},[_c('v-subheader',[_vm._v("General")]),_c('v-list-item',[_c('v-list-item-action',[_c('v-checkbox',{on:{"change":function($event){return _vm.updateUserSettings()}},model:{value:(_vm.userSettings.overrideLockoutPeriod),callback:function ($$v) {_vm.$set(_vm.userSettings, "overrideLockoutPeriod", $$v)},expression:"userSettings.overrideLockoutPeriod"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Override Lockout Period")]),_c('v-list-item-subtitle',[_vm._v("Override the lockout period so you can submit invoices on the 1st and 2nd of the month?")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }