<template>
  <div>
    <v-menu offset-y left nudge-bottom="14" min-width="230" content-class="user-profile-menu-content">
      <template v-slot:activator="{ on, attrs }">
        <v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4" dot>
          <v-avatar size="40px" v-bind="attrs" color="primary" class="v-avatar-light-bg primary--text" v-on="on">
            <v-img :src="require('@/assets/images/avatars/1.png')"></v-img>
          </v-avatar>
        </v-badge>
      </template>
      <v-list>
        <div class="pb-3 pt-2">
          <v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4" dot>
            <v-avatar size="40px" color="primary" class="v-avatar-light-bg primary--text">
              <v-img :src="require('@/assets/images/avatars/1.png')"></v-img>
            </v-avatar>
          </v-badge>
          <div class="d-inline-flex flex-column justify-center ms-3" style="vertical-align: middle">
            <span v-if="userData" class="text--primary font-weight-semibold mb-n1"> {{ userData.name }} </span>
            <small class="text--disabled text-capitalize">Admin</small>
          </div>
        </div>

        <!-- <v-divider></v-divider> -->

        <!-- Profile -->
        <!-- <v-list-item>
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiAccountOutline }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Profile</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->

        <!-- Email -->
        <!-- <v-list-item href="#">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiEmailOutline }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Inbox</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->

        <!-- Chat -->
        <!-- <v-list-item href="#">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiChatOutline }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Chat</v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-badge inline color="error" content="2"> </v-badge>
          </v-list-item-action>
        </v-list-item> -->

        <v-divider class="my-2"></v-divider>

        <!-- Settings -->
        <!-- <v-hover v-slot="{ hover }">
          <router-link style="text-decoration: none; color: inherit" to="/settings/settings">
            <v-list-item :style="{ 'background-color': hover ? '#3e3a57' : '' }">
              <v-list-item-icon class="me-2">
                <v-icon size="22">
                  {{ icons.mdiCogOutline }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Settings</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link></v-hover
        > -->
        <!-- /Settings -->

        <!-- Pricing -->
        <!-- <v-list-item href="#">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiCurrencyUsd }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Pricing</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->

        <!-- Switch Company -->
        <v-hover v-slot="{ hover }">
          <router-link style="text-decoration: none; color: inherit" to="/chooseOrg">
            <v-list-item :style="{ 'background-color': hover ? '#3e3a57' : '' }">
              <v-list-item-icon class="me-2">
                <v-icon size="22">
                  {{ icons.mdiSwapHorizontal }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Switch Company</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </v-hover>
        <v-divider class="my-2"></v-divider>

        <!-- User Settings -->
        <v-hover v-if="userData && userData.email && userData.email === 'admin@jdcservices.ca'" v-slot="{ hover }">
          <v-list-item
            :style="{ 'background-color': hover ? '#3e3a57' : '' }"
            @click="userSettingsDialog = true"
            style="cursor: pointer; text-decoration: none; color: inherit"
          >
            <v-list-item-icon class="me-2">
              <v-icon size="22">
                {{ icons.mdiCog }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Settings</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-hover>
        <v-divider
          v-if="userData && userData.email && userData.email === 'admin@jdcservices.ca'"
          class="my-2"
        ></v-divider>

        <!-- Logout -->
        <v-hover v-slot="{ hover }">
          <v-list-item :style="{ 'background-color': hover ? '#3e3a57' : '' }" style="cursor: pointer">
            <v-list-item-icon class="me-2">
              <v-icon size="22">
                {{ icons.mdiLogoutVariant }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title @click="signOut()">Log out</v-list-item-title>
            </v-list-item-content>
          </v-list-item></v-hover
        >
      </v-list>
    </v-menu>

    <!-- USER SETTINGS DIALOG -->
    <v-row justify="center">
      <v-dialog v-model="userSettingsDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card>
          <v-toolbar color="primary">
            <v-btn icon @click="userSettingsDialog = false">
              <v-icon size="22">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
            <v-toolbar-title>Settings</v-toolbar-title>
          </v-toolbar>
          <v-list three-line subheader>
            <v-subheader>General</v-subheader>
            <v-list-item>
              <v-list-item-action>
                <v-checkbox v-model="userSettings.overrideLockoutPeriod" @change="updateUserSettings()"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Override Lockout Period</v-list-item-title>
                <v-list-item-subtitle
                  >Override the lockout period so you can submit invoices on the 1st and 2nd of the
                  month?</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- /USER SETTINGS DIALOG -->
  </div>
</template>

<script>
import {
  mdiAccountOutline,
  mdiChatOutline,
  mdiCheckboxMarkedOutline,
  mdiClose,
  mdiCog,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiEmailOutline,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
  mdiSwapHorizontal,
} from '@mdi/js'
import firebase from 'firebase'

export default {
  data() {
    return {
      userData: null,
      org: null,
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
        mdiSwapHorizontal,
        mdiCog,
        mdiClose,
      },
      userSettingsDialog: false,
      userSettings: {
        overrideLockoutPeriod: false,
      },
    }
  },
  methods: {
    signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          // Sign-out successful.
          sessionStorage.clear()
          this.$router.push({ path: '/login' })
        })
        .catch(error => {
          // An error happened.
          console.log(error)
        })
    },
    async updateUserSettings() {
      try {
        // updated user settings in firestore
        await firebase.firestore().doc(`users/${this.userData.uid}`).update({
          settings: this.userSettings,
        })

        // update user settings in store
        let userDataCopy = JSON.parse(JSON.stringify(this.userData))
        userDataCopy['settings'] = this.userSettings
        this.$store.commit('setUserData', userDataCopy)
      } catch (err) {
        console.log(err)
      }
    },
  },
  mounted() {
    this.userData = this.$store.state.userData
    if (this.userData && this.userData.settings && this.userData.settings.overrideLockoutPeriod) {
      this.userSettings.overrideLockoutPeriod = this.userData.settings.overrideLockoutPeriod
    }
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
