import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import firebase from 'firebase/app'
import 'firebase/firestore'
import LogRocket from 'logrocket'
import Vue from 'vue'
import Toasted from 'vue-toasted'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
LogRocket.init('jdc-services-ltd/contractor-portal-fltko')

Vue.config.productionTip = false
Vue.use(Toasted)
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyAVUp5Gog2UQgwLImCph7jUCWw_1fDLWVM',
  authDomain: 'jdcservices-69fd4.firebaseapp.com',
  projectId: 'jdcservices-69fd4',
  storageBucket: 'jdcservices-69fd4.appspot.com',
  messagingSenderId: '792505781321',
  appId: '1:792505781321:web:2e95d849417eef4e13c398',
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

let app
firebase.auth().onAuthStateChanged(user => {
  //get user data and store in vuex/session storage

  if (user) {
    firebase
      .firestore()
      .collection('users')
      .doc(user.uid)
      .get()
      .then(doc => {
        let data = {
          name: doc.data().name,
          orgs: doc.data().orgs,
          uid: doc.id,
          email: doc.data().email,
          settings: doc.data().settings ? doc.data().settings : { overrideLockoutPeriod: false },
        }
        store.commit('setUserData', data)
        sessionStorage.setItem('userData', JSON.stringify(data))
        if (!app) {
          app = new Vue({
            router,
            store,
            vuetify,
            render: h => h(App),
          }).$mount('#app')
        }
      })
      .catch(error => {
        console.log(error)
      })
  } else {
    if (!app) {
      app = new Vue({
        router,
        store,
        vuetify,
        render: h => h(App),
      }).$mount('#app')
    }
  }
})
